import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { AuthenticationState } from 'react-aad-msal';
import { squads } from '../config/squads';
import { HeroBanner } from '../components/HeroBanner';
import { TeamPeople } from '../components/TeamPeople';
import { VideoPanel } from '../components/VideoPanel';
import { SocialPhotosCarousel } from '../components/SocialPhotosCarousel';
import { ThankYouNote } from '../components/ThankYouNote';
import { SaveButton } from '../components/SaveButton';
import { GenerateLinkButton } from '../components/GenerateLinkButton';
import { Loading } from '../components/Loading';
import { authProvider, getBearerAuthToken } from '../services/auth';
import { NotFoundPage } from './NotFoundPage';

const VideoGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.lightGrey};
`;

const SocialPhotosGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.darkGrey};
`;

const Padding = styled.div`
  @media (min-width: 601px) {
    padding: 60px 0;
  }

  @media (max-width: 600px) {
    padding: 40px 0;
  }
`;

const TeamPadding = styled.div`
  @media (min-width: 601px) {
    padding-top: 60px;
    padding-bottom: 20px;
  }

  @media (max-width: 600px) {
    padding-top: 40px;
    padding-bottom: 10px;
  }
`;

const GenerateLinkPadding = styled.div`
  @media (min-width: 961px) {
    padding-bottom: 40px;
  }

  @media (max-width: 960px) {
    padding-bottom: 60px;
  }
`;

const SaveButtonContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  padding: 8px;
  background-color: rgba(0, 0, 0, 50%);
  z-index: 100;
`;

export const SquadPage = () => {
  const { squadKey, token } = useParams();

  const isAuthenticated = authProvider.authenticationState === AuthenticationState.Authenticated;

  const [isLoading, setIsLoading] = useState(true);
  const [squadData, setSquadData] = useState({});
  const [contentChanges, setContentChanges] = useState({ name: squadKey });
  const [socialPhotosChanges, setSocialPhotosChanges] = useState({});
  const [peopleDataChanges, setPeopleDataChanges] = useState({});
  const [welcomeMessageChanges, setWelcomeMessageChanges] = useState({});
  const [bannerChanges, setBannerChanges] = useState({});

  const handlePeopleDataChange = useCallback((changedContent) => {
    setPeopleDataChanges({ people: changedContent });
  }, []);

  const handleSocialPhotosChange = useCallback((changedContent) => {
    setSocialPhotosChanges({ socialPhotos: changedContent });
  }, []);

  const handleWelcomeMesageChange = useCallback((changedContent) => {
    setWelcomeMessageChanges({ welcomeMessage: changedContent });
  }, []);

  const handleBannerChange = useCallback((changedContent) => {
    setBannerChanges({ bannerImageUrl: changedContent });
  }, []);

  useEffect(() => {
    const fetchSquadData = async () => {
      const authToken = isAuthenticated ? await getBearerAuthToken() : '';
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/content/${squadKey}/${token || ''}`, {
        headers: {
          Authorization: authToken
        }
      });
      return response.status === 200 ? response.json() : null;
    };
    if (squads[squadKey]) {
      fetchSquadData()
        .then((res) => setSquadData(res))
        .catch(() => setSquadData(null))
        .finally(() => setIsLoading(false));
    }
  }, [squadKey, token, isAuthenticated]);

  useEffect(() => {
    setContentChanges((changes) => ({
      ...changes,
      ...socialPhotosChanges,
      ...peopleDataChanges,
      ...welcomeMessageChanges,
      ...bannerChanges
    }));
  }, [socialPhotosChanges, peopleDataChanges, welcomeMessageChanges, bannerChanges]);

  if (!squads[squadKey] || !squadData) {
    return <NotFoundPage />;
  }

  if (isLoading) {
    return <Loading />;
  }

  const { videoUrl } = squads[squadKey];
  const { name, welcomeMessage, bannerImageUrl, people, socialPhotos } = squadData;

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <HeroBanner bannerImageUrl={bannerImageUrl} onBannerChange={handleBannerChange} />
        </Grid>
        <Grid item>
          <TeamPadding>
            <TeamPeople
              squadName={name}
              welcomeMessage={welcomeMessage}
              peopleData={people}
              onPeopleDataChange={handlePeopleDataChange}
              onWelcomeMessageChange={handleWelcomeMesageChange}
            />
          </TeamPadding>
        </Grid>
        <VideoGrid item>
          <Padding>
            <VideoPanel videoUrl={videoUrl} />
          </Padding>
        </VideoGrid>
        <SocialPhotosGrid item>
          <Padding>
            <SocialPhotosCarousel socialPhotos={socialPhotos} onSocialPhotosChange={handleSocialPhotosChange} />
          </Padding>
        </SocialPhotosGrid>
        <Grid item>
          <Padding>
            <ThankYouNote />
          </Padding>
        </Grid>
        <Grid item>
          {isAuthenticated && (<GenerateLinkPadding><GenerateLinkButton squad={squadKey} /></GenerateLinkPadding>)}
        </Grid>
      </Grid>
      {isAuthenticated && (
        <SaveButtonContainer>
          <SaveButton squad={squadKey} updatedContent={contentChanges} />
        </SaveButtonContainer>
      )}
    </>
  );
};
