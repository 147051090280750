import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Button, Container, Grid, Card, CardMedia, CardContent, Typography, useTheme, useMediaQuery
} from '@material-ui/core';
import { AuthenticationState } from 'react-aad-msal';
import { ArrowBack, ArrowForward, CloudUpload, DeleteForever } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { authProvider } from '../services/auth';
import { Editable } from './Editable';

const ScaledCard = styled(Card)`
  @media (max-width: 600px) {
    width: 144px !important;
    padding: 5px;
  }
`;

const CircularCardMedia = styled(CardMedia)`
  border-radius: 50%;
`;

const WelcomeHeading = styled(Typography)`
  color: ${({ theme }) => theme.primaryRed};
  text-transform: uppercase;

  @media (max-width: 600px) {
    padding-bottom: 12px;
  }

  @media (min-width: 601px) {
    padding-bottom: 24px;
  }
`;

const WelcomeMessage = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};
`;

const EditableMessage = styled(Editable)`
  @media (max-width: 600px) {
    padding-bottom: 40px;
  }

  @media (min-width: 601px) {
    padding-bottom: 64px;
  }
`;

const DropzoneRoot = styled(Container)`
  text-align: center;
  padding: 16px;

  color: ${({ theme }) => theme.secondaryBlack};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondaryBlack};
    color: ${({ theme }) => theme.secondaryWhite};
    opacity: 0.6;
  }
`;

const ImageControlsContainer = styled(Grid)`
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
`;

const ControlButton = styled(Button)`
  @media (max-width: 600px) {
    &.control-button-root {
      min-width: 0px;
      padding: 0px 0px;
    }
  }
`;

export const TeamPeople = ({ squadName, welcomeMessage, peopleData, onPeopleDataChange, onWelcomeMessageChange }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const displayManagementControls = authProvider.authenticationState === AuthenticationState.Authenticated;
  const [people, setPeople] = useState(JSON.parse(JSON.stringify(peopleData || [])));
  const [message, setMessage] = useState(welcomeMessage || '');
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setPeople(JSON.parse(JSON.stringify(peopleData || [])));
    setMessage(welcomeMessage || '[Enter welcome message]');
  }, [peopleData, welcomeMessage]);

  useEffect(() => {
    onPeopleDataChange(people);
  }, [people, onPeopleDataChange]);

  useEffect(() => {
    onWelcomeMessageChange(message);
  }, [message, onWelcomeMessageChange]);

  const playAnimation = (id) => {
    document.getElementById(id).play();
  };

  const onDropAccepted = (files) =>
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => setPeople([...people, { url: target.result, name: 'Name', role: 'Role' }]);
      reader.readAsDataURL(file);
    });

  const onDropRejected = (rejectedFiles) =>
    toast.error(`File(s) are the wrong type: "${rejectedFiles.map((file) => file.name).join('", "')}"`);

  const deleteClip = (ppl, clipUrl) =>
    ppl.filter((p) => clipUrl !== p.url);

  const moveClipBackward = (ppl, clipUrl) => {
    const originalIndex = ppl.findIndex((p) => p.url === clipUrl);
    return (originalIndex === 0)
      ? [
        ...ppl.slice(1),
        ppl[0]
      ] : [
        ...ppl.slice(0, originalIndex - 1),
        ppl[originalIndex],
        ppl[originalIndex - 1],
        ...ppl.slice(originalIndex + 1)
      ];
  };

  const moveClipForward = (ppl, clipUrl) => {
    const originalIndex = ppl.findIndex((p) => p.url === clipUrl);
    return (originalIndex === ppl.length - 1)
      ? [
        ppl[ppl.length - 1],
        ...ppl.slice(0, ppl.length - 1)
      ] : [
        ...ppl.slice(0, originalIndex),
        ppl[originalIndex + 1],
        ppl[originalIndex],
        ...ppl.slice(originalIndex + 2)
      ];
  };

  const handlePersonalDataChange = (key, value, index) => {
    const person = people[index];
    const updatedPerson = { ...person, [key]: value };
    const updatedPeopleData = (index === 0)
      ? [updatedPerson, ...people.slice(1)]
      : (
        (index === people.length - 1)
          ? [...people.slice(0, index), updatedPerson]
          : [...people.slice(0, index), updatedPerson, ...people.slice(index + 1)]
      );
    setPeople(updatedPeopleData);
  };

  return (
    <Container fixed>
      <WelcomeHeading variant={isMobileView ? 'h4' : 'h2'} component="h1" align="center">
        Meet the {squadName} Squad
      </WelcomeHeading>
      <EditableMessage
        isEditable={displayManagementControls}
        text={message}
        placeholder="Enter welcome message"
        onChangeCallback={(value) => setMessage(value || welcomeMessage)}
      >
        <WelcomeMessage variant={isMobileView ? 'h6' : 'h5'} component="p" align="center" paragraph>
          {message}
        </WelcomeMessage>
      </EditableMessage>
      {(!people || people.length === 0) && (
        <Typography color="textSecondary" component="p" align="center">
          Sorry, no team members found.
        </Typography>
      )}
      {people && people.length > 0 && (
        <Grid container direction="row" justify="center" spacing={isMobileView ? 2 : 6}>
          {people.map(({ url, name, role }, i) => (
            <Grid item key={i} onTouchStart={() => playAnimation(i)}>
              <ScaledCard elevation={0}>
                <CircularCardMedia
                  id={i}
                  src={`${url}#t=0.1`}
                  component="video"
                  type="video/mp4"
                  muted
                  disableRemotePlayback /* disable android cast button */
                  playsInline /* disable open video as a separate playback on iPhone */
                  preload="metadata"
                  onMouseOver={() => playAnimation(i)}
                  onClick={() => playAnimation(i)}
                  onTouchStart={() => playAnimation(i)}
                />
                <CardContent>
                  <Editable
                    isEditable={displayManagementControls}
                    text={name}
                    placeholder="Enter name"
                    onChangeCallback={(value) => handlePersonalDataChange('name', value || 'Name', i)}
                  >
                    <Typography component="h3" variant={isMobileView ? 'body2' : 'h5'} align="center">
                      {name}
                    </Typography>
                  </Editable>
                  <Editable
                    isEditable={displayManagementControls}
                    text={role}
                    placeholder="Enter role"
                    onChangeCallback={(value) => handlePersonalDataChange('role', value || 'Role', i)}
                  >
                    <Typography component="p" variant={isMobileView ? 'caption' : 'body1'} align="center">
                      {role}
                    </Typography>
                  </Editable>
                  {displayManagementControls && (
                    <ImageControlsContainer container direction="row" justify="space-between">
                      <Grid item>
                        <ControlButton
                          classes={{ root: 'control-button-root' }}
                          variant="contained"
                          onClick={() => setPeople(moveClipBackward(people, url))}
                        >
                          <ArrowBack />
                        </ControlButton>
                      </Grid>
                      <Grid item>
                        <ControlButton
                          classes={{ root: 'control-button-root' }}
                          variant="contained"
                          onClick={() => setPeople(deleteClip(people, url))}
                        >
                          <DeleteForever />
                        </ControlButton>
                      </Grid>
                      <Grid item>
                        <ControlButton
                          classes={{ root: 'control-button-root' }}
                          variant="contained"
                          onClick={() => setPeople(moveClipForward(people, url))}
                        >
                          <ArrowForward />
                        </ControlButton>
                      </Grid>
                    </ImageControlsContainer>
                  )}
                </CardContent>
              </ScaledCard>
            </Grid>
          ))}
        </Grid>
      )}
      {displayManagementControls && (
        <Grid item>
          <Dropzone onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} accept="video/mp4">
            {({ getRootProps, getInputProps }) => (
              <DropzoneRoot {...getRootProps()}>
                <input {...getInputProps()} />
                <CloudUpload fontSize="large" />
                <Typography variant="h5">
                  Drag or Click to add
                </Typography>
              </DropzoneRoot>
            )}
          </Dropzone>
        </Grid>
      )}
    </Container>
  );
};
