import React, { useState } from 'react';
import { TextField } from '@material-ui/core';

export const Editable = ({ isEditable, text, placeholder, onChangeCallback, children, ...props }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const handleKeyDown = (e) => {
    const { key } = e;
    const allKeys = ['Escape', 'Tab', 'Enter'];

    if (allKeys.indexOf(key) > -1) {
      setIsEditMode(false);
    }
  };

  return (
    <section {...props}>
      { isEditable && isEditMode ? (
        <div onBlur={() => setIsEditMode(false)} onKeyDown={(e) => handleKeyDown(e)}>
          <TextField
            fullWidth
            text={text}
            placeholder={placeholder}
            onChange={(e) => onChangeCallback(e.target.value)}
          />
        </div>) : (
          <div onClick={() => setIsEditMode(true)} onKeyDown={() => setIsEditMode(true)}>
            {children}
          </div>)}
    </section>
  );
};
