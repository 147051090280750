export const telstraTheme = {
  primaryRed: '#ed1f4f',
  primaryBlue: '#0e39fb',
  secondaryBlack: '#000',
  secondaryWhite: '#fff',
  lightGrey: '#f0efee',
  mediumGrey: '#cfcfcf',
  darkGrey: '#979797',
  validationRed: '#c00'
};
