import React from 'react';
import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';

const PaddedContainer = styled(Container)`
  padding: 3rem 0;
`;

export const NotFoundPage = () => (
  <PaddedContainer>
    <Typography variant="h3" component="h1" align="center">
      404 Not Found
    </Typography>
    <Typography variant="body1" component="p" align="center">
      This page does not exist.
    </Typography>
  </PaddedContainer>
);
