import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const FooterGrid = styled(Grid)`
  border-top: 1px solid ${({ theme }) => theme.lightGrey};
`;

export const AppFooter = ({ children }) => (
  <FooterGrid container justify="center" spacing={3}>
    <Grid item>
      { children }
    </Grid>
  </FooterGrid>
);
