import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box, Container, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { AuthenticationState } from 'react-aad-msal';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { CloudUpload } from '@material-ui/icons';
import { authProvider } from '../services/auth';

const Parent = styled(Box)`
  position: relative;
  background-color: ${({ theme }) => theme.primaryRed};
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  
  @media (min-width: 601px) {
    height: calc((100vh - 126px) / 2); /* 126px is height of AppBar component */
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc((100vh - 126px) / 2); /* 126px is height of AppBar component */
  }
`;

const BackgroundImage = styled.div`
  /* background-image set by style attribute */
  background-size: cover;
  display: block;
  height: 100%;

  @media (min-width: 601px) {
    background-position: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    background-position: top;
  }
`;

const DropzoneRoot = styled(Container)`
  text-align: center;
  padding: 16px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: ${({ theme }) => theme.secondaryWhite};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondaryBlack};
    opacity: 0.6;
  }
`;

export const HeroBanner = ({ bannerImageUrl, onBannerChange }) => {
  const displayManagementControls = authProvider.authenticationState === AuthenticationState.Authenticated;
  const [bannerUrl, setBannerUrl] = useState(bannerImageUrl || '');
  const firstRender = useRef(true);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const bannerRef = useRef({});
  const [bannerSize, setBannerSize] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setBannerSize({
        width: bannerRef.current.parentElement.clientWidth,
        height: bannerRef.current.parentElement.clientHeight
      });
    }, 1);
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setBannerUrl(bannerImageUrl || '');
  }, [bannerImageUrl, bannerRef]);

  useEffect(() => {
    onBannerChange(bannerUrl);
  }, [bannerUrl, onBannerChange]);

  const onDropAccepted = (files) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => setBannerUrl(target.result);
    reader.readAsDataURL(files.pop());
  };

  const onDropRejected = (rejectedFiles) =>
    toast.error(`File(s) are the wrong type: "${rejectedFiles.map((file) => file.name).join('", "')}"`);

  const BannerContent = () => (
    <Parent>
      <BackgroundImage ref={bannerRef} style={{ backgroundImage: `url("${bannerUrl}")` }} />
      {displayManagementControls && (
        <Dropzone onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} accept="image/jpeg">
          {({ getRootProps, getInputProps }) => (
            <DropzoneRoot {...getRootProps()}>
              <input {...getInputProps()} />
              <CloudUpload fontSize="large" />
              <Typography variant="h5">
                Drag or Click to change
              </Typography>
              <Typography variant="body1">
                W: {bannerSize.width}px * H: {bannerSize.height}px
              </Typography>
            </DropzoneRoot>
          )}
        </Dropzone>
      )}
    </Parent>
  );

  if (!isMobileView) {
    return (
      <Container fixed>
        <BannerContent />
      </Container>
    );
  }

  return <BannerContent />;
};
