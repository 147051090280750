import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { getBearerAuthToken } from '../services/auth';

export const SaveButton = ({ squad, updatedContent }) => {
  const [isSaving, setSaving] = useState(false);

  const sendRequest = async () => {
    const authToken = await getBearerAuthToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/content/${squad}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken
      },
      body: JSON.stringify(updatedContent || {})
    });
    return response.status === 200 ? response.json() : null;
  };

  const save = () => {
    setSaving(true);

    sendRequest()
      .then((res) => (res
        ? toast.success('Changes saved successfully!')
        : toast.error('Error saving changes!')))
      .catch(() => toast.error('Error saving changes!'))
      .finally(() => setSaving(false));
  };

  return (
    <Button startIcon={<Save />} variant="contained" color="secondary" size="large" disabled={isSaving} onClick={save}>
      Save all changes
    </Button>
  );
};
