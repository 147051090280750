import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AzureAD } from 'react-aad-msal';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { RouteSwitch } from './components/RouteSwitch';
import { ThemeProvider } from 'styled-components';
import { telstraTheme } from './theme';
import { authProvider } from './services/auth';
import { AppHeader } from './components/AppHeader';
import { AppFooter } from './components/AppFooter';
import { AuthenticationControl } from './components/AuthenticationControl';

toast.configure({
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1500
});

ReactDOM.render(
  <React.StrictMode>
    <AzureAD provider={authProvider}>
      {(authProviderProps) => (
        <ThemeProvider theme={telstraTheme}>
          <AppHeader />
          <BrowserRouter>
            <RouteSwitch />
          </BrowserRouter>
          <AppFooter>
            <AuthenticationControl {...authProviderProps} />
          </AppFooter>
        </ThemeProvider>
      )}
    </AzureAD>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
