import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AuthenticationState } from 'react-aad-msal';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward, CloudUpload, DeleteForever } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import AliceCarousel from 'react-alice-carousel';
import { authProvider } from '../services/auth';
import 'react-alice-carousel/lib/alice-carousel.css';

const ImageBox = styled(Box)`
  white-space: nowrap;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
`;

const ImageFrame = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    height: 210px;
  }

  @media (min-width: 601px) {
    height: 420px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageControlsContainer = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 50%);
  padding: 8px;
`;

const DropzoneRoot = styled(Container)` 
  text-align: center;
  padding: 16px;

  color: ${({ theme }) => theme.secondaryBlack};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.secondaryBlack};
    color: ${({ theme }) => theme.secondaryWhite};
    opacity: 0.6;
  }
`;

const Arrow = styled(Image)`
  position: absolute;
  z-index: 1;
  width: 75px;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @media (max-width: 600px) {
    width: 36px;
  }

  @media (min-width: 601px) {
    width: 72px;
  }
`;

const LeftArrow = styled(Arrow)`
  border-radius: 50%;

  @media (max-width: 600px) {
    left: 10px;
  }

  @media (min-width: 601px) {
    left: 25px;
  }
`;

const RightArrow = styled(Arrow)`
  border-radius: 50%;

  @media (max-width: 600px) {
    right: 10px;
  }

  @media (min-width: 601px) {
    right: 25px;
  }
`;

export const SocialPhotosCarousel = ({ socialPhotos, onSocialPhotosChange }) => {
  const [photos, setPhotos] = useState(JSON.parse(JSON.stringify(socialPhotos || [])));
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setPhotos(JSON.parse(JSON.stringify(socialPhotos || [])));
  }, [socialPhotos]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    onSocialPhotosChange(photos);
  }, [photos, onSocialPhotosChange]);

  const displayManagementControls = authProvider.authenticationState === AuthenticationState.Authenticated;

  const onDropAccepted = (files) =>
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = ({ target }) => setPhotos([...photos, target.result]);
      reader.readAsDataURL(file);
    });

  const onDropRejected = (rejectedFiles) =>
    toast.error(`Unsupported file type: "${rejectedFiles.map((file) => file.name).join('", "')}"`);

  const deletePhoto = (ppl, imageUrl) =>
    ppl.filter((url) => imageUrl !== url);

  const movePhotoBackward = (ppl, imageUrl) => {
    const originalIndex = ppl.indexOf(imageUrl);
    return (originalIndex === 0)
      ? [
        ...ppl.slice(1),
        ppl[0]
      ] : [
        ...ppl.slice(0, originalIndex - 1),
        ppl[originalIndex],
        ppl[originalIndex - 1],
        ...ppl.slice(originalIndex + 1)
      ];
  };

  const movePhotoForward = (ppl, imageUrl) => {
    const originalIndex = ppl.indexOf(imageUrl);
    return (originalIndex === ppl.length - 1)
      ? [
        ppl[ppl.length - 1],
        ...ppl.slice(0, ppl.length - 1)
      ] : [
        ...ppl.slice(0, originalIndex),
        ppl[originalIndex + 1],
        ppl[originalIndex],
        ...ppl.slice(originalIndex + 2)
      ];
  };

  const responsive = {
    0: {
      items: 1
    },
    600: {
      items: 2
    },
    1024: {
      items: 3
    }
  };

  let aliceCarouselRef;

  return (
    <Grid container direction="column" alignItems="stretch" spacing={3}>
      <Grid item>
        <ImageBox>
          {(!photos || photos.length === 0) && (
            <Typography color="textSecondary" component="p" align="center">
              Sorry, no social photos found.
            </Typography>
          )}
          {photos && photos.length > 0 && (
            <div>
              <LeftArrow src="/images/previous.png" onClick={() => aliceCarouselRef.slidePrev()} />
              <RightArrow src="/images/next.png" onClick={() => aliceCarouselRef.slideNext()} />
            </div>
          )}
          <AliceCarousel
            responsive={responsive}
            autoPlay={!displayManagementControls}
            autoPlayInterval={5000}
            mouseTrackingEnabled
            dotsDisabled
            buttonsDisabled
            ref={(obj) => { aliceCarouselRef = obj; }}
          >
            {photos.map((imageUrl, i) => (
              <ImageFrame key={i}>
                <Image src={imageUrl} key={i} />
                {displayManagementControls && (
                  <ImageControlsContainer>
                    <Grid container direction="row" justify="space-between">
                      <Grid item>
                        <Button variant="contained" onClick={() => setPhotos(movePhotoBackward(photos, imageUrl))}>
                          <ArrowBack />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={() => setPhotos(deletePhoto(photos, imageUrl))}>
                          <DeleteForever />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" onClick={() => setPhotos(movePhotoForward(photos, imageUrl))}>
                          <ArrowForward />
                        </Button>
                      </Grid>
                    </Grid>
                  </ImageControlsContainer>
                )}
              </ImageFrame>
            ))}
          </AliceCarousel>
        </ImageBox>
      </Grid>
      {displayManagementControls && (
        <Grid item>
          <Dropzone onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} accept="image/jpeg">
            {({ getRootProps, getInputProps }) => (
              <DropzoneRoot {...getRootProps()}>
                <input {...getInputProps()} />
                <CloudUpload fontSize="large" />
                <Typography variant="h5">
                  Drag or Click to add
                </Typography>
              </DropzoneRoot>
            )}
          </Dropzone>
        </Grid>
      )}
    </Grid>
  );
};
