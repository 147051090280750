import React, { useEffect } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { Autorenew, Person } from '@material-ui/icons';
import { Spin } from './Spin';

export const AuthenticationControl = ({ login, logout, authenticationState, accountInfo, error }) => {
  useEffect(() => {
    if (error && error.errorMessage) {
      console.error(error);
      toast.error(`Authentication error: ${error.errorMessage}`);
    }
  });

  const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
  const isInProgress = authenticationState === AuthenticationState.InProgress;
  const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;

  return (
    <Button
      color="primary"
      variant="text"
      size="small"
      startIcon={isInProgress ? <Spin><Autorenew /></Spin> : <Person />}
      disabled={isInProgress}
      onClick={isAuthenticated ? logout : login}
    >
      {isAuthenticated && `Sign out (${accountInfo.account.name})`}
      {isInProgress && 'Signing in'}
      {isUnauthenticated && ''}
    </Button>
  );
};
