import React from 'react';
import styled, { useTheme } from 'styled-components';
import { AppBar, Toolbar } from '@material-ui/core';
import { TelstraPurpleLogo } from './TelstraPurpleLogo';

const BlackAppBar = styled(AppBar)`
  &[role=banner] { /* Required to increase specificity to allow override without !important */
    background-color: ${({ theme }) => theme.secondaryBlack};
    box-shadow: none;
  }
`;

const Logo = styled.span`
  width: 132px;
  height: 94px;
  margin: 1rem 0;
  
  @media (max-width: 600px) {
    padding-left: 16px;
  }
  
  @media (min-width: 601px) {
    padding-left: 30px;
  }
`;

export const AppHeader = () => {
  const theme = useTheme();
  return (
    <BlackAppBar position="static" role="banner">
      <Toolbar>
        <Logo aria-label="logo">
          <a href="https://purple.telstra.com" target="_blank" rel="noopener noreferrer">
            <TelstraPurpleLogo color={theme.secondaryWhite} />
          </a>
        </Logo>
      </Toolbar>
    </BlackAppBar>
  );
};
