import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SquadPage } from '../pages/SquadPage';
import { NotFoundPage } from '../pages/NotFoundPage';

export const RouteSwitch = () => (
  <Switch>
    <Route path={['/squad/:squadKey/:token', '/squad/:squadKey']}>
      <SquadPage />
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);
