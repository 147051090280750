import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Container } from '@material-ui/core';

const CircularProgressContainer = styled(Container)`
  color: ${({ theme }) => theme.primaryRed};
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
`;

const PurpleCircularProgress = styled(CircularProgress)`
  position: relative;
  left: -40px;
`;

export const Loading = () => (
  <CircularProgressContainer fixed>
    <PurpleCircularProgress color="inherit" />
  </CircularProgressContainer>
);
