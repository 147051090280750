import React, { useState } from 'react';
import { Button, Container, Grid, TextField } from '@material-ui/core';
import { Fingerprint } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { getBearerAuthToken } from '../services/auth';

export const GenerateLinkButton = ({ squad }) => {
  const [isGenerating, setGenerating] = useState(false);
  const [link, setLink] = useState('');

  const sendRequest = async () => {
    const authToken = await getBearerAuthToken();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/token/generate/${squad}`, {
      headers: {
        Authorization: authToken
      }
    });
    return response.status === 200 ? response.json() : null;
  };

  const generateLink = () => {
    setGenerating(true);
    setLink('');

    sendRequest()
      .then((res) => (res
        ? setLink(`${process.env.REACT_APP_WEB_URL}/squad/${squad}/${res}`)
        : toast.error('Error generating link!')))
      .catch(() => toast.error('Error generating link!'))
      .finally(() => setGenerating(false));
  };

  return (
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <TextField
            fullWidth
            value={link}
            variant="outlined"
            disabled
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            startIcon={<Fingerprint />}
            variant="contained"
            color="secondary"
            disabled={isGenerating}
            onClick={generateLink}
          >
            Generate Link
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
