export const squads = {
  sunshine: {
    name: 'Sunshine',
    videoUrl: '/images/squad-video.mp4',
  },
  quokka: {
    name: 'Quokka',
    videoUrl: '/images/squad-video.mp4'
  }
};
