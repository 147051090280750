import React from 'react';
import styled from 'styled-components';
import {
  Container, Grid, Typography, useMediaQuery, useTheme
} from '@material-ui/core';

const ThankYouHeading = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};
  text-transform: uppercase;
`;

const ThankYouMessage = styled(Typography)`
  color: ${({ theme }) => theme.secondaryBlack};
`;

const PurpleLink = styled.a`
  color: ${({ theme }) => theme.primaryRed};
`;

export const ThankYouNote = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container fixed>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <ThankYouHeading variant={isMobileView ? 'h4' : 'h3'} component="h2" align="center">
            Thank you
          </ThankYouHeading>
        </Grid>
        <Grid item>
          <ThankYouMessage variant="body1" component="p" align="center">
            Thank you for taking the time to view your potential new squad!
            If you want to know more about us, check out the<> </>
            <PurpleLink href="https://purple.telstra.com/">Telstra Purple website</PurpleLink>.
          </ThankYouMessage>
        </Grid>
        <Grid item>
          <ThankYouMessage variant="body1" component="p" align="center">
            We look forward to speaking with you in your next interview!
          </ThankYouMessage>
        </Grid>
      </Grid>
    </Container>
  );
};
