import { LoginType, MsalAuthProvider } from 'react-aad-msal';

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY,
      clientId: process.env.REACT_APP_MSAL_CLIENT_APP_ID,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true
    }
  },
  {
    scopes: [`${process.env.REACT_APP_MSAL_CLIENT_APP_ID}/.default`]
  },
  {
    loginType: LoginType.Redirect
  }
);

export const getBearerAuthToken = async () => {
  const token = await authProvider.getAccessToken();
  return token.accessToken ? `Bearer ${token.accessToken}` : '';
};
